<template>
    <v-container>

        <!-- HEADER -->
        <v-row class="mt-5" align="center">
            <v-col cols="12">
                <div class="title-section">
                    Usuarios
                </div>
            </v-col>
            <v-col cols="4">
                <v-icon color="black" large>mdi-account-supervisor-circle-outline</v-icon>
                <b>Usuarios creados</b>
            </v-col>
            <v-col cols="4">
                <v-btn v-if="isAdmin || isAmbUser" class="normal-btn" color="primary" depressed @click="showCreateDialog">
                    <v-icon left>mdi-plus-circle-outline</v-icon>
                    Crear Usuario
                </v-btn>
            </v-col>
            <v-col cols="4">
                <kimsa-text-field
                    placeholder="Buscar"
                    append-icon="mdi-magnify"
                    outlined dense solo flat
                    @change="search = $event"
                />
            </v-col>
        </v-row>

        <!-- TABLE -->
        <v-data-table
            class="mt-5"
            :headers="headers"
            :loading="loading"
            :items="users"
            :search.sync="search"
            :items-per-page="5"
        >
            <template v-slot:item.actions="{ item }">
                <v-btn class="mr-1 my-1" fab outlined x-small @click.stop="editItem(item)">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn v-if="isAdmin" class="mr-1 my-1" fab outlined x-small @click.stop="deleteItem(item)">
                    <v-icon color="primary">mdi-delete</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <!-- COMPONENTS -->
        <create-user-dialog @success="loadUsers" ref="creator" />

        <kimsa-confirm
            title="Eliminar"
            content="¿Esta seguro que desea eliminar este Usuario?"
            persistent
            @confirm="onConfirmDelete"
            @cancel="currentId = null"
            :loading="loadingDelete"
            no-close-on-confirm
            ref="confirm"
        />

    </v-container>
</template>

<script>
import Axios from "@/utils/Axios";
import KimsaConfirm from "@/components/common/KimsaConfirm";
import KimsaTextField from "@/components/common/inputs/KimsaTextField";
import CreateUserDialog from "@/components/user/CreateUserDialog";

export default {
    name: "Users",
    components: {CreateUserDialog, KimsaTextField, KimsaConfirm},
    data() {
        return {
            loading: false,
            users: [],
            search: '',
            currentId: null,
            loadingDelete: false,
            headers: [
                {text: 'Nombre', value: 'name', class: 'grey white--text'},
                {text: 'Actualización', value: 'updated_at', class: 'grey white--text'},
                {text: '', value: 'actions', sortable: false, class: 'grey'},
            ],
        }
    },
    async mounted() {
        const vm = this
        await vm.loadUsers()
    },
    methods: {
        async loadUsers() {
            const vm = this
            vm.loading = true

            await Axios.post('users').then(res => {
                console.log('res loadUsers',res)
                vm.users = res.data.result.users.map(user => {
                    return {
                        id: user.id,
                        name: user.name,
                        created_at: vm.$moment(user.created_at).format('DD-MM-YYYY'),
                        updated_at: vm.$moment(user.updated_at).format('DD-MM-YYYY'),
                    }
                })
                console.log('vm.users',vm.users)
            }).catch(er => {
                console.log('error loadUsers', er)
            }).finally(() => {
                vm.loading = false
            })
        },
        showCreateDialog() {
            const vm = this
            vm.$refs.creator.show()
        },
        editItem(item) {
            const vm = this
            vm.$refs.creator.loadUser(item.id)
        },
        deleteItem(item) {
            const vm = this
            vm.$refs.confirm.show()
            vm.currentId = item.id
        },
        async onConfirmDelete() {
            const vm = this
            vm.loadingDelete = true

            await Axios.delete(`user/${vm.currentId}/delete`).then(res => {
                // console.log('res onConfirmDelete',res)
                vm.toast('Usuario eliminado')
            }).catch(er => {
                console.log('er',er)
                vm.toast('error onConfirmDelete', er)
            })

            vm.loadingDelete = false
            vm.currentId = null

            vm.$refs.confirm.hide()
            await vm.loadUsers()
        },
    }
}
</script>

<style scoped>

.title-section {
    color: var(--v-primary-base);
}

</style>